<template>
  <div class="p-1">
    <!-- TABS FOR STATUS -->
    <b-nav card-header pills class="m-0">
      <b-nav-item
        exact-active-class="active router-link-active"
        :link-classes="[
          'sub-tab-nav',
          'px-3',
          [8, 9, 10].includes(statusNav) ? 'router-link-active' : '',
          bgTabsNavs,
        ]"
        :active="[8, 9, 10].includes(statusNav)"
        @click="changeNav(8)"
      >
        Active
        <span class="ml-2"
          ><feather-icon
            icon
            :badge="status[0].total"
            badge-classes="badge-danger"
        /></span>
      </b-nav-item>
      <b-nav-item
        exact-active-class="active router-link-active"
        :link-classes="[
          'sub-tab-nav',
          'px-3',
          [11, 12, 13].includes(statusNav) ? 'router-link-active' : '',
          bgTabsNavs,
        ]"
        :active="[11, 12, 13].includes(statusNav)"
        @click="changeNav(11)"
      >
        Loyal
        <span class="ml-2"
          ><feather-icon
            icon
            :badge="status[1].total"
            badge-classes="badge-danger"
        /></span>
      </b-nav-item>
      <b-nav-item
        exact-active-class="active router-link-active"
        :link-classes="[
          'sub-tab-nav',
          'px-3',
          [2, 4, 6].includes(statusNav) ? 'router-link-active' : '',
          bgTabsNavs,
        ]"
        @click="changeNav(2)"
        :active="[2, 4, 6].includes(statusNav)"
      >
        Outstanding
        <span class="ml-2"
          ><feather-icon
            icon
            :badge="status[2].total"
            badge-classes="badge-danger"
        /></span>
      </b-nav-item>
    </b-nav>
    <b-card no-body class="border-top-info border-3 border-table-radius px-0">
      <filter-slot
        :filter="filters"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        :send-multiple-sms="false"
        :change-columns-by-client="true"
        @reload="updateTable"
      >
        <template #buttons>
          <div class="d-flex justify-content-end align-items-center w-80">
            <div></div>
            <div>
              <b-button
                v-for="showStatus in showStatus"
                :key="showStatus.id"
                :variant="
                  showStatus.id == statusNav ? 'primary' : 'outline-primary'
                "
                class="ml-1"
                :disabled="showStatus.id == statusNav"
                @click="changeNav(showStatus.id)"
                style="position: relative"
              >
                {{ showStatus.name }}
                <span class="position-absolute" style="top: 0; right: 0">
                  <feather-icon
                    icon
                    :badge="showStatus.total"
                    badge-classes="badge-danger"
                  />
                </span>
              </b-button>
            </div>
          </div>
        </template>
        <template #custom-vselect>
          <FilterStatusAccount :filters="filters[3]" />
        </template>
        <template #table>
          <b-table
            slot="table"
            ref="refClientsList"
            small
            no-provider-filtering
            :items="myProvider"
            :fields="visibleFields"
            primary-key="id"
            table-class="text-nowrap"
            responsive="sm"
            show-empty
            sticky-header="70vh"
            :busy.sync="isBusy"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
          >
            <template #buttons>
              <b-button
                v-for="showStatus in showStatus"
                :key="showStatus.id"
                :variant="
                  showStatus.id == statusNav ? 'primary' : 'outline-primary'
                "
                class="ml-1"
                :disabled="showStatus.id == statusNav"
                @click="changeNav(showStatus.id)"
                style="position: relative"
              >
                {{ showStatus.name }}
                <span class="position-absolute" style="top: 0; right: 0">
                  <feather-icon
                    icon
                    :badge="showStatus.total"
                    badge-classes="badge-danger"
                  />
                </span>
              </b-button>
            </template>
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <!-- Selected Title -->
            <template #head(selected)>
              <b-form-checkbox v-model="selectAll" @input="selectedAll" />
            </template>

            <!-- Column NAME -->
            <template #cell(lead_name)="data">
              <router-link
                :class="[textLink]"
                :to="{
                  name: 'bookeeping-dashboard',
                  params: {
                    idClient: JSON.parse(data.item.accounts)[0].account_id,
                  },
                }"
                target="_blank"
                >{{ data.item.lead_name }}
              </router-link>
              <br />
              <span v-if="data.item.user_responsible" class="text-warning">
                <tabler-icon
                  :icon="
                    data.item.user_responsible === currentUser.user_id
                      ? 'StarIcon'
                      : 'LockIcon'
                  "
                  size="15"
                />
                <strong>CEO's client</strong>
              </span>
              <div>
                <span style="font-weight: 100">{{ data.item.mobile }}</span>
              </div>
            </template>
            <!-- account -->
            <template #cell(account)="data">
              <div>
                <ul style="padding-left: 0px; margin-bottom: 0px">
                  <li
                    v-for="(account, index) in JSON.parse(data.item.accounts)"
                    :key="index"
                    style="list-style: none"
                  >
                    <a
                      v-if="moduleId == 4"
                      :href="
                        '/administration/clients/account/' + account.account_id
                      "
                      >{{ account.account }}</a
                    >
                    <span v-else style="font-weight: 100">{{
                      account.account
                    }}</span>
                  </li>
                </ul>
                <ul style="padding-left: 0px; margin-bottom: 0px">
                  <li
                    v-for="(account, index) in JSON.parse(data.item.accounts)"
                    :key="index"
                    style="list-style: none"
                  >
                    <status-account :account="account" :text="true" />
                  </li>
                </ul>
              </div>
            </template>
            <!-- COLUMN OTHER PROGRAMS -->
            <template #cell(qty_services)="{ item }">
              <ClientPrograms
                :clientData="item"
                :programName="JSON.parse(item.accounts)[0].program"
                :programId="JSON.parse(item.accounts)[0].program_id"
              />
            </template>
            <!-- Column FEE & CHARGES -->
            <template #cell(fee_charges)="data">
              <div
                v-for="(account, index) in JSON.parse(data.item.accounts)"
                :key="index"
              >
                <span>{{
                  account.charges ? "$ " + account.charges : null
                }}</span>
              </div>
            </template>
            <!-- Column MP -->
            <template
              v-if="
                (moduleId != 2 && moduleId != 6) ||
                (moduleId == 6 && currentUser.role_id != 4)
              "
              #cell(monthly_amount)="data"
            >
              <div>
                <ul style="padding-left: 0px; margin-bottom: 0px">
                  <li
                    v-for="(account, index) in JSON.parse(data.item.accounts)"
                    :key="index"
                    style="list-style: none"
                  >
                    $ {{ account.monthly_amount }}
                  </li>
                </ul>
              </div>
            </template>
            <!-- Column LP -->
            <template
              v-if="
                (moduleId != 2 && moduleId != 6) ||
                (moduleId == 6 && currentUser.role_id != 4)
              "
              #cell(last_payment)="data"
            >
              <div>
                <ul style="padding-left: 0px; margin-bottom: 0px">
                  <li
                    v-for="(account, index) in JSON.parse(data.item.accounts)"
                    :key="index"
                    style="list-style: none; position: relative"
                  >
                    <span style="margin-left: 15px">
                      {{
                        account.last_payment == "-" ? "" : account.last_payment
                      }}
                      <b-img
                        v-if="account.state_lp == 1"
                        :src="assetsImg + '/images/icons/lastpayment-out.ico'"
                        style="
                          position: absolute;
                          left: 0;
                          top: 50%;
                          transform: translate(0, -50%);
                        "
                      />
                      <b-img
                        v-else
                        :src="assetsImg + '/images/icons/lastpayment-in.ico'"
                        style="
                          position: absolute;
                          left: 0;
                          top: 50%;
                          transform: translate(0, -50%);
                        "
                      />
                    </span>
                  </li>
                </ul>
              </div>
            </template>
            <!-- Column PT -->
            <template
              v-if="
                (moduleId != 2 && moduleId != 6) ||
                (moduleId == 6 && currentUser.role_id != 4)
              "
              #cell(status_payment)="data"
            >
              <div>
                <ul style="padding-left: 0px; margin-bottom: 0px">
                  <li
                    v-for="(account, index) in JSON.parse(data.item.accounts)"
                    :key="index"
                    style="list-style: none"
                  >
                    <status-payment :account="account" />
                  </li>
                </ul>
              </div>
            </template>

            <!-- column UT -->
            <template #cell(uncomplete_task)="data">
              <div>
                <ul style="padding-left: 0px; margin-bottom: 0px">
                  <li
                    v-for="(account, index) in JSON.parse(data.item.accounts)"
                    :key="index"
                    style="list-style: none"
                  >
                    <feather-icon id="calendar" icon="CalendarIcon" size="20" />
                    <div
                      style="
                        position: relative;
                        bottom: 15px;
                        left: 6.7px;
                        font-size: 85%;
                        font-weight: bold;
                      "
                    >
                      {{ account.task }}
                    </div>
                  </li>
                </ul>
              </div>
            </template>
            <!-- column UT -->
            <template #cell(enrol_date)="data">
              <div>
                <ul style="padding-left: 0px; margin-bottom: 0px">
                  <li
                    v-for="(account, index) in JSON.parse(data.item.accounts)"
                    :key="index"
                    style="list-style: none"
                  >
                    {{ account.created_at | myGlobal }}
                  </li>
                </ul>
              </div>
            </template>
            <!-- Column ADVISOR -->
            <template
              v-if="currentUser.role_id != 3 || moduleId == 2"
              #cell(state_advisor)="data"
            >
              <div>
                <ul style="padding-left: 0px; margin-bottom: 0px">
                  <li
                    v-for="(account, index) in JSON.parse(data.item.accounts)"
                    :key="index"
                    style="list-style: none"
                  >
                    <div>
                      <feather-icon
                        id="icon"
                        :title="
                          account.state_advisor == 1
                            ? 'Active'
                            : account.state_advisor == 2
                            ? 'Busy'
                            : account.state_advisor == 3
                            ? 'Away'
                            : 'Offline'
                        "
                        icon="CircleIcon"
                        size="13"
                        :style="
                          account.state_advisor == 1
                            ? 'background:green'
                            : account.state_advisor == 2
                            ? 'background:orange'
                            : account.state_advisor == 3
                            ? 'background:red'
                            : 'background:#ccc'
                        "
                      />
                      {{ account.advisor_name }}
                    </div>
                  </li>
                </ul>
              </div>
            </template>
            <!-- Column LN -->
            <template
              v-if="
                (moduleId != 2 && moduleId != 4 && moduleId != 6) ||
                (moduleId == 6 && currentUser.role_id != 4)
              "
              #cell(s_note)="data"
            >
              <div>
                <ul
                  style="
                    padding-left: 0px;
                    margin-bottom: 0px;
                    list-style: none;
                  "
                >
                  <li
                    v-for="(account, index) in JSON.parse(data.item.accounts)"
                    :key="index"
                    class="li-created-at-account"
                    :class="
                      account.s_note <= 1
                        ? ''
                        : account.s_note <= 2
                        ? 'text-warning'
                        : 'text-danger'
                    "
                  >
                    {{ account.last_note }}
                  </li>
                </ul>
              </div>
            </template>
            <!--          Column Access Credential-->
            <template #cell(access_cred)="data">
              <span
                class="cursor-pointer"
                @click="
                  openModalListCredentials(
                    JSON.parse(data.item.accounts)[0].account_id,
                    JSON.parse(data.item.accounts)[0].id,
                    data.item.lead_name
                  )
                "
                >AC</span
              >
            </template>
            <!-- Column SERVICES -->
            <template
              v-if="
                currentUser.role_id == 1 ||
                currentUser.role_id == 2 ||
                currentUser.role_id == 3
              "
              #cell(service)="data"
            >
              <actions-table
                v-if="
                  data.item.user_responsible == currentUser.user_id ||
                  data.item.user_responsible == null
                "
                :options="serviceOptions"
                :row-data="data.item"
                @onRowDelete="onRowDelete"
                @onRowEdit="onRowEdit"
                @onRowProcess="onRowProcess"
                @modalChangeService="modalChangeService"
                @modalAddService="modalAddService"
              />
            </template>
          </b-table>
        </template>
      </filter-slot>
    </b-card>
    <!-- Modal send sms -->
    <modal-send-sms
      v-if="modalSendSms"
      :modal-send-sms="modalSendSms"
      :name-leads="clientsSms[0]"
      :typesms="0"
      @closeModal="closeSendSms"
    />

    <!-- Modal call rounds -->
    <modal-call-rounds
      v-if="modalCall"
      :modal-call-rounds="modalCall"
      :name-leads="clientsSms"
      :type-call="0"
      @updateTable="updateTable"
      @closeModal="closeModalCallRounds"
    />
    <!-- Modal Send Email -->
    <modal-send-email
      v-if="modalSendEmail"
      :modal-send-email="modalSendEmail"
      :name-leads="clientsSms"
      :typesms="0"
      @closeModal="closeSendSms"
    />
    <!-- Modal Add Services -->
    <modal-add-service
      v-if="modalOpenAddService"
      :modal-open-add-service="modalOpenAddService"
      :details-client="detailsClient"
      :type-modal="typeModal"
      @hideModal="closeModalService"
      @changeProgram="openModalProgram"
    />
    <list-access-credentials-modal
      v-if="modalListCredentialsOn"
      :credential-id="credentialId"
      :account-number="accountNumber"
      :client-name="clientNameToPass"
      @close="closeModalListCredentials"
    />
    <!-- Component Commons -->
    <component
      :is="modalData.programs.programSelected"
      v-if="modal.programs"
      :type-modal="modalData.programs.typeModal"
      :sales-client="saleClientP"
      :programs-all="programsAll"
      :users-services="usersServices"
      :header-s="headerS"
      @closeModal="hideModalProgram"
      @changeProgram="openModalProgram"
    />
  </div>
</template>

<script>
// Table clients
import fields from "@/views/bookeeping/views/clients/data/fields.clients.data";

// service
// import ClientService from '@/views/boost-credit/services/clients.service'

import BusinessModal from "@/views/crm/views/sales-made/components/modals/services/BuisnessModal.vue";
import CreditExpertsModal from "@/views/crm/views/sales-made/components/modals/services/credit-experts/CreditExpertsModal.vue";
import BoostCreditModal from "@/views/crm/views/sales-made/components/modals/services/BoostCreditModal.vue";
import GeneralSupportModal from "@/views/crm/views/sales-made/components/modals/services/GeneralSupportModal.vue";
import KeyBookModal from "@/views/crm/views/sales-made/components/modals/services/KeyBookModal.vue";
import ParagonModal from "@/views/crm/views/sales-made/components/modals/services/ParagonModal.vue";
import SpecialistModal from "@/views/crm/views/sales-made/components/modals/services/SpecialistModal.vue";
import TaxResearchModal from "@/views/crm/views/sales-made/components/modals/services/TaxResearchModal.vue";
import DebtSolutionModal from "@/views/crm/views/sales-made/components/modals/services/DebtSolutionModal.vue";
import ModalAddService from "@/views/commons/components/clients/modals/ModalAddService.vue";
import ModalCallRounds from "@/views/boost-credit/views/clients/components/clients/modals/SendToCallRounds.vue";
import ModalSendEmail from "@/views/commons/components/clients/modals/ModalSendEmail.vue";
import ModalSendSms from "@/views/commons/components/clients/modals/ModalSendSms.vue";
import StatusPayment from "@/views/commons/components/clients/stylescomponents/StatusPayment.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import ClientService from "@/views/commons/components/clients/services/clients.services";
import ActionsTable from "@/views/crm/views/Lead/lead-table/ActionsTable.vue";
import LeadService from "@/views/commons/components/lead-programs/service/lead.service";
import FiltersClientsData from "@/views/bookeeping/views/clients/data/filters.clients.data";
import ListAccessCredentialsModal from "@/views/boost-credit/views/clients/components/clients/modals/ListAccessCredentialsModal.vue";
import NcrLeadsService from "@/views/administration/views/ncr-leads/services/NcrLeads.service.js";
import FilterStatusAccount from "@/views/commons/components/clients/stylescomponents/FilterStatusAccount.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import ClientServiceBusiness from "@/views/business/views/clients/services/clients.service.js";
import ClientPrograms from "@/views/commons/components/client-programs/ClientPrograms.vue";
import { modifyVisibility } from "@/helpers/fields-table";

export default {
  components: {
    // modals
    BusinessModal,
    CreditExpertsModal,
    ListAccessCredentialsModal,
    BoostCreditModal,
    GeneralSupportModal,
    KeyBookModal,
    ParagonModal,
    SpecialistModal,
    TaxResearchModal,
    DebtSolutionModal,
    ModalCallRounds,
    ModalAddService,
    ModalSendEmail,
    ModalSendSms,
    StatusPayment,
    StatusAccount,
    ActionsTable,
    NcrLeadsService,
    FilterStatusAccount,
    ClientPrograms,
  },
  data() {
    return {
      headerS: {},
      goals: [],
      clientNameToPass: "",
      modalData: {
        programs: {
          programSelected: "",
          typeModal: 0,
          salesClient: {},
        },
      },
      modal: {
        programs: false,
      },
      modalOpenAddService: false,
      typeModal: null,
      clients: null,
      clientsSms: [],
      selectAll: 0, // total
      allClients: [], // lista de listas
      arrayCurrentPage: [],
      filters: FiltersClientsData,
      fields,
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      totalAmount: 0,
      sortBy: "",
      sortDesc: true,
      startPage: null,
      endPage: "",
      nextPage: "",
      toPage: null,
      isBusy: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client...",
        model: "",
      },
      tiemposegundos: 30,
      tiempominutos: 0,
      time1: true,
      pnttime: false,
      advisor: "",
      modalSendSms: false,
      modalSendEmail: false,
      modalCall: false,
      serviceOptions: [],
      actionOptions: [],
      detailsClient: {},
      saleClientP: {},
      programsAll: [],
      usersServices: [],
      items: [],
      event: null,
      modalListCredentialsOn: false,
      credentialId: "",
      accountNumber: "",
      typeAddChange: null,
      statusNav: 8,
      status: [
        {
          id: 8,
          total: 0,
          subStatus: [
            { id: 8, name: "Current", total: 0 },
            { id: 9, name: "1 Month Late", total: 0 },
            { id: 10, name: "2 Month Late", total: 0 },
          ],
        },
        {
          id: 11,
          total: 0,
          subStatus: [
            { id: 11, name: "In Progress", total: 0 },
            { id: 12, name: "Potential", total: 0 },
            { id: 13, name: "Stand By", total: 0 },
          ],
        },
        {
          id: 2,
          total: 0,
          subStatus: [
            { id: 2, name: "Hold", total: 0 },
            { id: 4, name: "Canceled", total: 0 },
            { id: 6, name: "Closed", total: 0 },
          ],
        },
      ],
    };
  },
  async created() {
    await this.usersPrograms();
    this.filters[2].options = [{ id: 0, user_name: "All" }];
    this.validateColumnsByRole();
    await this.usersAllPrograms();
    await this.getAllStatusClientAccount();
    this.counterClients();
  },
  async mounted() {
    if (this.currentUser.role_id == 3) {
      modifyVisibility(this.fields, "s_note", false);
    }
    this.serviceOptions.push("addService");
    this.serviceOptions.push("changeService");
    this.actionOptions.push("sendToCallReturns");
    this.actionOptions.push("sendSMS");
    this.actionOptions.push("sendEmail");
  },
  methods: {
    async changeNav(value) {
      this.statusNav = value;
      this.$refs.refClientsList.refresh();
    },
    updateTable() {
      this.$refs.refClientsList.refresh();
    },
    async counterClients() {
      const response = await ClientServiceBusiness.counterClients({
        program_id: 8,
      });
      response.forEach((element) => {
        this.status.forEach((status) => {
          if (status.id == element.status) {
            status.total = element.count;
          }
          status.subStatus.forEach((subStatus) => {
            if (subStatus.id == element.status) {
              subStatus.total = element.count;
            }
          });
        });
      });
    },
    // actions
    async modalToCallOpen(id, name, account, nameAccount, nameProgram) {
      const result = await this.showConfirmSwal("Are you sure?");
      if (result.value) {
        this.clientsSms = [
          {
            id,
            name,
            account,
            nameAccount,
            nameProgram,
          },
        ];
        this.modalCall = true;
      }
    },
    openModalListCredentials(id, accNumber, clientName) {
      this.modalListCredentialsOn = true;
      this.credentialId = id;
      this.accountNumber = accNumber;
      this.clientNameToPass = clientName;
    },
    closeModalListCredentials() {
      this.modalListCredentialsOn = false;
    },
    async modalSendEmailOpen(id, name) {
      const result = await this.showConfirmSwal("Are you sure?");
      if (result.value) {
        this.clientsSms = [{ id, name }];
        this.modalSendEmail = true;
      }
    },
    async modalSmsOpen(id, name) {
      const result = await this.showConfirmSwal("Are you sure?");
      if (result.value) {
        this.clientsSms = [{ id, name }];
        this.modalSendSms = true;
      }
    },
    // buttons
    openModalCallRounds() {
      this.modalCall = true;
    },
    openModalSendSms() {
      this.modalSendSms = true;
    },
    openModalSendEmail() {
      this.modalSendEmail = true;
    },
    closeSendSms() {
      this.modalSendSms = false;
      this.modalSendEmail = false;
    },
    closeModalCallRounds() {
      this.modalCall = false;
    },
    validateColumnsByRole() {
      if (this.currentUser.role_id === 3) {
        this.filters[2].visible = false;
      } else if (this.currentUser.role_id === 4) {
        this.fields[14].visible = false;
        visibleFields(this.fields, "uncomplete_task", true);
      }
    },
    changeTypePayment(type) {
      if (type == "paymentType") {
        const { filOne, filTwo } =
          this.currentUser.role_id != 4
            ? { filOne: 4, filTwo: 5 }
            : { filOne: 3, filTwo: 4 };
        if (this.filters[filOne].model == 1) {
          this.filters[filTwo].visible = true;
        } else {
          this.filters[filTwo].model = null;
          this.filters[filTwo].visible = false;
        }
      }
    },
    async contador() {
      try {
        this.pnttime = true;
        this.updateReloj();
        // setTimeout(() => {
        //   this.pnttime = false;
        // }, 1000 * this.tiemposegundos + 60000 * this.tiempominutos);
        await ClientService.generateRefresh();
      } catch (error) {
        console.log(error);
      }
    },
    updateReloj() {
      this.tiemposegundos = this.tiemposegundos;
      this.tiempominutos = this.tiempominutos;
      if (this.tiempominutos == 0 && this.tiemposegundos == 0) {
        this.$refs.refClientsList.refresh();
        this.pnttime = false;
        this.tiemposegundos = 59;
        this.tiempominutos = 1;
      } else {
        // Restamos un segundo al tiempo restante
        if (this.tiemposegundos == 0) {
          this.tiemposegundos = 59;
          if (this.tiempominutos != 0) {
            this.tiempominutos -= 1;
          }
        }
        this.tiemposegundos -= 1;
        // Ejecutamos nuevamente la función al pasar 1000 milisegundos (1 segundo)
        setTimeout(() => {
          this.updateReloj();
        }, 1000);
      }
    },
    selectedAll() {
      if (this.selectAll == 1) {
        const nameCl = [];
        this.clients.map((dato) => {
          nameCl.push({
            id: JSON.parse(dato.accounts)[0].account_id,
            name: dato.lead_name,
          });
        });
        const value = this.allClients.find(
          (element) => element.currentPage == this.paginate.currentPage
        );
        if (!value) {
          this.allClients.push({
            currentPage: this.paginate.currentPage,
            array: nameCl,
          });
        }
      }
      if (this.selectAll == 0) {
        const value = this.allClients.find(
          (element) => element.currentPage == this.paginate.currentPage
        );
        if (value) {
          const index = this.allClients.indexOf(value);
          this.allClients.splice(index, 1);
        }
      }
      this.clientsSms = [];
      for (let i = 0; i < this.allClients.length; i++) {
        this.clientsSms = [...this.clientsSms, ...this.allClients[i].array];
      }
    },
    async myProvider(ctx) {
      let sortBy = 5;
      let sortDirection = "desc";
      if (ctx.sortBy === "lead_name") {
        sortBy = 2;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      } else if (ctx.sortBy === "account") {
        sortBy = 4;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      } else if (ctx.sortBy === "monthly_amount") {
        sortBy = 43;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      } else if (ctx.sortBy === "last_payment") {
        sortBy = 44;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      } else if (ctx.sortBy === "uncomplete_task") {
        sortBy = 46;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      } else if (ctx.sortBy === "status_payment") {
        sortBy = 48;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      } else if (ctx.sortBy === "s_note") {
        sortBy = 45;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      } else if (ctx.sortBy === "enrol_date") {
        sortBy = 5;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      }
      try {
        let module = 0;

        switch (parseInt(this.moduleId)) {
          case 2:
            module = this.program;
            break;
          case 4:
            module = this.program;
            break;
          case 3:
            module = 1;
            break;
          case 7:
            module = 2;
            break;
          case 6:
            module = 3;
            break;
          case 5:
            module = 4;
            break;
          case 8:
            module = this.program;
            break;
          case 10:
            module = 6;
            break;
          case 14:
            module = 8;
            break;
        }
        if (parseInt(this.moduleId) != 2 && parseInt(this.moduleId) != 4) {
          if (
            this.currentUser.role_id == 1 ||
            this.currentUser.role_id == 2 ||
            (this.currentUser.role_id == 4 && this.moduleId == 7)
          ) {
            this.advisor = this.filters[2].model;
          } else if (this.currentUser.role_id == 3) {
            this.advisor = this.currentUser.user_id;
          } else {
            this.advisor = null;
          }
        } else {
          this.advisor = this.filters[2].model;
        }
        const params = {
          per_page: ctx.perPage,
          text: this.filterPrincipal.model,
          from: this.filters[0].model,
          to: this.filters[1].model,
          program: module,
          orderby: sortBy,
          order: sortDirection,
          status: this.statusNav,
          advisor: this.advisor,
          type: this.filters[4].model,
          day: this.paymentDay,
          rol_id: this.currentUser.role_id,
        };
        const data = await amgApi.post(
          `/clients/search-clients?page=${ctx.currentPage}`,
          params
        );
        this.items = data.data.data;
        this.clients = this.items;
        this.startPage = data.data.from;
        this.paginate.currentPage = data.data.current_page;
        this.paginate.perPage = data.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.data.last_page;
        this.totalRows = data.data.total;
        this.toPage = data.data.to;
        return this.items || [];
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async usersPrograms() {
      try {
        const params = {
          idmodule: this.moduleId,
          iduser: this.currentUser.user_id,
          idrole: this.currentUser.role_id,
        };
        if (this.currentUser.role_id) {
          const data = await ClientService.usersPrograms(params);
          this.filters[2].label = "Advisor";
          this.filters[2].selectText = "user_name";
          this.filters[2].options = data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async searchPrograms(lead_id) {
      try {
        const response = await ClientService.searchClientPayment({
          id: lead_id,
        });
        if (response.status == 200) {
          const programs = response.data;
          const data = await ClientService.programs();
          this.programsAll = data.data;
          programs.sort((a, b) => a.program_id - b.program_id);
          for (let i = 0; i < this.programsAll.length; i++) {
            for (let y = 0; y < programs.length; y++) {
              if (this.programsAll[i].id == programs[y].program_id) {
                this.programsAll.splice(i, 1);
              }
            }
          }
          const sper = this.currentUser.sale_permission;
          const spn = [];
          if (sper.length != this.programsAll.length) {
            sper.map((spe) => {
              this.programsAll.map((pro) => {
                if (spe.id == pro.id) {
                  spn.push(pro);
                }
              });
            });
            this.programsAll = spn;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async usersAllPrograms() {
      try {
        const data = await ClientService.usersAllPrograms({
          id: this.moduleId,
        });
        this.usersServices = data.data; // .filter(item => item.id != 1);
        this.usersServices.unshift({ id: 1, user_name: "CEO" });
      } catch (error) {
        console.log(error);
      }
    },
    onRowDelete(id) {},
    async onRowEdit(data) {},
    async modalChangeService(data) {
      const result = await this.showConfirmSwal("Are you sure change service?");
      if (result.value) {
        this.typeAddChange = 2;
        await this.createEvent(data);
        this.modalGeneralService(data, 4);
      }
    },
    onRowProcess(id) {},
    async modalAddService(data) {
      const result = await this.showConfirmSwal(
        "Are sure want to add new service?"
      );
      if (result.value) {
        this.typeAddChange = 1;
        this.modalGeneralService(data, 3);
        await this.createEvent(data);
      }
    },
    async modalSendSMS(data) {
      const result = await this.showConfirmSwal("Are sure want to send SMS?");
      if (result.value) {
        this.modalGeneralService(data, 2);
      }
    },
    async modalSendEmailMenu(data) {
      const result = await this.showConfirmSwal("Are sure want to send Email?");
      if (result.value) {
        this.modalGeneralService(data, 2);
      }
    },
    ...mapActions("crm-store", ["SET_PROGRAM", "SET_CATCHER", "SET_SELLER"]),
    modalGeneralService(data, type) {
      this.detailsClient = data;
      this.typeModal = type;
      this.SET_PROGRAM(null);
      this.SET_CATCHER(null);
      this.SET_SELLER(null);
      this.modalOpenAddService = true;
    },
    closeModalService() {
      this.modalOpenAddService = false;
    },
    async createEvent(data) {
      const response = await LeadService.createLeadEvent({
        user_id: this.currentUser.user_id,
        lead_id: data.lead_id,
      });
      this.event = response.id;
    },
    async openModalProgram(data) {
      if (data.captured.id && data.program.id && data.seller.id) {
        const response = await this.showConfirmSwal(
          "Are you sure?",
          "You won't be able to revert this!"
        );
        if (response.isConfirmed) {
          await this.addPreloader();
          const param = {
            prices: null,
            observation: "Service",
            contract: 1,
            program: data.program.id,
            fee: null,
            suggested: null,
            otherpricesp: null,
            event: this.event,
            json_noce: null,
            stateid: 0,

            // Diferents to add change Services
            account: JSON.parse(this.detailsClient.accounts)[0].account_id,
            captured: data.captured.id,
            seller: data.seller.id,
            type: this.typeAddChange,
            user_id: this.currentUser.user_id,
            module: this.moduleId,
            id_score: null,
            json_ce: null,
            type_client: "CLIENT",
          };
          try {
            const result = await LeadService.insertSaleMade(param);
            if (result.status == 200) {
              this.modalOpenAddService = false;
              this.$router
                .push({ name: "sales-made-bookeeping-client-pending" })
                .catch((err) => {
                  console.log(err);
                });
              this.removePreloader();
            }
          } catch (error) {
            this.showErrorSwal(
              "Please, verify if the client already has the service, otherwise contact support.",
              "Error"
            );
          } finally {
            this.removePreloader();
          }
        }
      }
    },
    async hideModalProgram(refresh) {
      if (refresh) {
        // await this.updateRow();
        this.showToast(
          "success",
          "top-right",
          "Success!",
          "CheckIcon",
          "Successful operation"
        );
      }

      this.modalData.programs.programSelected = "";
      this.modal.programs = false;
      this.removePreloader();
    },
    async getAllStatusClientAccount() {
      this.filters[3].options = [];
      const { data } = await NcrLeadsService.getAllStatusClientAccount();
      this.filters[3].options.push({
        value: 0,
        label: "All",
        icon: "CircleIcon",
      });
      this.filters[3].options.push(...data);
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    showStatus() {
      let statusParent = 2;

      if ([8, 9, 10].includes(this.statusNav)) {
        statusParent = 8;
      } else if ([11, 12, 13].includes(this.statusNav)) {
        statusParent = 11;
      }
      const status = this.status.find((status) => status.id === statusParent);
      return status?.subStatus;
    },
    lengthClients() {
      return this.clientsSms.length == 0;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    visibleFields() {
      return this.currentUser.role_id == 3
        ? this.fields.filter(
            (field) => field.key != "state_advisor" && field.visible
          )
        : this.fields.filter((field) => field.visible);
    },
    paymentType: {
      get() {
        return this.filters[4].model;
      },
      set(value) {
        this.filters[4].model = value;
      },
    },
    paymentDay: {
      get() {
        return this.filters[5].model;
      },
      set(value) {
        this.filters[5].visible = value;
      },
    },
  },
  watch: {
    paymentType(newVal) {
      if (newVal == 1) {
        this.paymentDay = true;
      } else {
        this.paymentDay = false;
      }
    },
  },
};
</script>

<style scoped>
#icon {
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 5px;
}
.w-80 {
  width: 80% !important;
}
</style>
